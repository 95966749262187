import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const Privacy = () => (
  <Layout>
    <SEO title="Privacy" />
    <section className="text-section">
      <h2>Personal Data</h2>
      <p>
        Box-scores does not collect any personal data, e.g. history, bookmarks,
        ip, etc.
      </p>
      <p>
        All of the Box-scores user settings are local to user's machine. The
        extensions uses the Chromium or WebExtensions Local Storage API to store
        any data. It does not share or send this information to anyone.
      </p>
      <p>Box-scores does not send any information to third party services.</p>
    </section>
  </Layout>
)

export default Privacy
